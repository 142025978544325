import http from '@/utils/http.js'

// 列表
export function closedCusList(data) {
    return http({
        url: "/jasoboss/customer/deal/list",
        method: "post",
        data
    })
}
// 添加
export function closedCusAdd(data) {
    return http({
        url: "/jasoboss/customer/deal/add",
        method: "post",
        data
    })
}
// 添加
export function closedCusEdit(data) {
    return http({
        url: "/jasoboss/customer/deal/update",
        method: "post",
        data
    })
}
// 获取
export function closedCusGet(data) {
    return http({
        url: "/jasoboss/customer/deal/get",
        method: "post",
        data
    })
}
// 根据客户ID 产品分组
export function closedCusProduct(data) {
    return http({
        url: "/jasoboss/customer/deal/list/product",
        method: "post",
        data
    })
}
//验证秘钥
export function verifySecret(data) {
    return http({
        url: "/jasoboss/customer/deal/verify/secret",
        method: "post",
        data
    })
}