<template>
    <div class="details_box">
        <div class="cust_box">
            <p class="title">
                <span> <i class="iconfont icon-wodekehu"></i>客户信息</span>
                <el-button
                    @click="onClose()"
                    size="mini"
                    type="primary"
                    icon="el-icon-arrow-left"
                    style="
                        background: #fff;
                        color: #2370eb;
                        border-color: #2370eb;
                    "
                    >返回列表</el-button
                >
            </p>
            <div class="info_box">
                <div>
                    <p style="min-width: 300px">
                        <span>企业名称</span><span>{{ data.companyName }}</span>
                    </p>
                    <p>
                        <span>客户</span><span>{{ data.customerName }}</span>
                    </p>
                    <p>
                        <span>产品ID</span
                        ><span>{{ data.identification }}</span>
                    </p>
                    <p v-if="type == 1">
                        <span>CorpID</span
                        ><span>{{ data.corpId || '- -' }}</span>
                    </p>
                    <p v-if="type == 2">
                        <span>邮箱版本</span
                        ><span>{{
                            data.emailVersion == 0
                                ? '标准版'
                                : data.emailVersion == 1
                                ? '尊享版'
                                : data.emailVersion == 2
                                ? '集团版'
                                : ''
                        }}</span>
                    </p>
                    <p v-if="type == 4">
                        <span>域名</span><span>{{ data.domain || '- -' }}</span>
                    </p>
                </div>
                <div>
                    <p v-if="type != 3 && type != 4">
                        <span>管理员账号</span
                        ><span>{{ data.adminAccount || '- -' }}</span>
                    </p>
                    <p>
                        <span>开通时间</span>

                        <span v-if="data.reportTime && data.reportExpireTime"
                            >{{ data.reportTime.slice(0, 10) }}~{{
                                data.reportExpireTime.slice(0, 10)
                            }}</span
                        >
                    </p>
                    <p v-if="type == 1">
                        <span>系统回收时间</span>

                        <span>
                            {{
                                data.recoveryTime
                                    ? data.recoveryTime.slice(0, 10)
                                    : ''
                            }}
                        </span>
                    </p>
                    <p v-if="type != 1">
                        <span>域名</span><span>{{ data.domain || '- -' }}</span>
                    </p>
                    <p v-if="type != 3">
                        <span>账号数</span><span>
                            <!-- {{ data.number || 0 }} -->
                            {{ dealNumber }}
                        </span>
                    </p>
                </div>
                <div>
                    <p v-if="type != 3">
                        <span>下单后台</span
                        ><span>{{ data.libraryName || '- -' }}</span>
                    </p>
                    <p>
                        <span>部门</span>
                        <span>
                            <el-tooltip placement="top">
                                <div slot="content">
                                    <p
                                        style="line-height: 2"
                                        v-for="item in data.departmentAdminDOList"
                                        :key="item.id"
                                    >
                                        {{ item.departmentName }}
                                    </p>
                                </div>
                                <span style="color: #333">{{
                                    returnName()
                                }}</span>
                            </el-tooltip>
                        </span>
                    </p>

                    <p>
                        <span>成员</span
                        ><span>{{ data.saleAdminName || '- -' }}</span>
                    </p>
                </div>
                <div style="position: relative" v-if="type == 1">
                    <!-- <p>
                        <span>年限</span>
                        <span v-if="type == 1">{{
                            Math.round(data.userTerm / 365)
                        }}</span>
                        <span v-else>{{ data.userTerm }}</span>
                    </p> -->

                    <p>
                        <span style="width: 50px">用途</span>
                        <span>
                            <el-input
                                type="textarea"
                                rows="5"
                                style="width: 220px"
                                v-model="data.purpose"
                                placeholder="请输入用途"
                                @blur="submitUpdate"
                            ></el-input>
                        </span>
                    </p>
                    <p class="purpose_time">
                        最后编辑时间：{{ data.purposeTime || '- -' }}
                    </p>
                </div>
            </div>
        </div>
        <div class="table_box" v-if="isTable">
            <p class="title">
                <span>
                    <i class="iconfont icon-dingdan3" style="color: #f5a623"></i
                    >交付信息</span
                >
            </p>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="tableData"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
                :expand-row-keys="expands"
                :row-key="getRowKeys"
            >
                <el-table-column width="10"> </el-table-column>

                <el-table-column
                    v-if="type != 3"
                    label="下单时间"
                    width="100"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span type="text">{{
                            scope.row.createTime
                                ? scope.row.createTime.slice(0, 10)
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20" v-if="type != 3">
                </el-table-column>
                <el-table-column
                    label="交付订单编号"
                    width="150"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.orderPlanNo || '- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20" v-if="type != 3">
                </el-table-column>
                <el-table-column label="版本" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span
                            class="text"
                            v-if="scope.row.productCategory == 1"
                            >{{
                                scope.row.version == 1
                                    ? '老版'
                                    : scope.row.version == 2
                                    ? '新版'
                                    : '- -'
                            }}</span
                        >
                        <span
                            class="text"
                            v-else-if="scope.row.productCategory == 2"
                            >{{
                                scope.row.version == 1
                                    ? '标准版'
                                    : scope.row.version == 2
                                    ? '尊享版'
                                    : scope.row.version == 3
                                    ? '集团版'
                                    : '- -'
                            }}</span
                        >
                        <span
                            class="text"
                            v-else-if="scope.row.productCategory == 4"
                            >{{
                                scope.row.version == 1
                                    ? 'Hmail旗舰版'
                                    : scope.row.version == 2
                                    ? 'Hmail5G'
                                    : scope.row.version == 3
                                    ? '尊享版'
                                    : '- -'
                            }}</span
                        >
                        <span v-else>- -</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20" v-if="type != 3">
                </el-table-column>
                <el-table-column
                    label="类型"
                    width="113"
                    show-overflow-tooltip
                    v-if="type != 3"
                >
                    <template slot-scope="scope">
                        <span v-if="type == 3">{{
                            scope.row.planType == 1
                                ? '新购'
                                : scope.row.planType == 2
                                ? '续费'
                                : '- -'
                        }}</span>
                        <span v-else>
                            {{
                                scope.row.productCategory == 1
                                    ? $MailStatus.planType(scope.row.planType)
                                    : scope.row.productCategory == 2
                                    ? $MailStatus.planTypeAli(
                                          scope.row.planType
                                      )
                                    : scope.row.productCategory == 4
                                    ? $MailStatus.planType(scope.row.planType)
                                    : '- -'
                            }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column min-width="20" v-if="type != 1">
                </el-table-column>
                <el-table-column
                    label="域名"
                    v-if="type != 1"
                    show-overflow-tooltip
                    width="150"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.domain ? scope.row.domain : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20" v-if="type != 3">
                </el-table-column>
                <el-table-column
                    label="账户数"
                    width="53"
                    prop="number"
                    v-if="type != 3"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="年限"
                    prop="term"
                    width="53"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <!-- <span v-if="type == 1 || type == 4">{{
                            scope.row.term
                                ? Math.round(scope.row.term / 365)
                                : '- -'
                        }}</span> -->
                        <span>{{
                            returnTrem(scope.row)
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="额度"
                    prop=""
                    width="80"
                    v-if="isField"
                    show-overflow-tooltip
                    ><template slot-scope="scope">
                        <span>{{
                            scope.row.amount
                                ? scope.row.amount.toFixed(2)
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="开通时间"
                    width="100"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.openTime
                                ? scope.row.openTime.slice(0, 10)
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="到期时间"
                    width="100"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.expireTime
                                ? scope.row.expireTime.slice(0, 10)
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    label="下单平台"
                    width="101"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.libraryName
                                ? scope.row.libraryName
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="销售订单" width="121">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="clickExpandMore(scope.row)"
                            style="color: #2370eb"
                            >{{ scope.row.orderNo }}</el-button
                        >
                    </template>
                </el-table-column>
                <el-table-column type="expand" width="5">
                    <template slot-scope="scope">
                        <p
                            class="title"
                            style="padding-top: 0; color: #333; font-size: 14px"
                        >
                            <span>
                                <i
                                    class="iconfont icon-ziliao"
                                    style="color: #f5a623"
                                ></i
                                >客户信息</span
                            >
                        </p>
                        <div class="orderInfo_box">
                            <div>
                                <p>
                                    <span>订单编号</span
                                    ><span>{{
                                        orderplan ? orderplan.orderNo : '- -'
                                    }}</span>
                                </p>
                                <p>
                                    <span>订单状态</span
                                    ><span>{{
                                        orderplan.status == 1
                                            ? '未交付'
                                            : orderplan.status == 2
                                            ? '部分交付'
                                            : orderplan.status == 3
                                            ? '已交付'
                                            : '- -'
                                    }}</span>
                                </p>
                                <p>
                                    <span>创建时间</span
                                    ><span>{{
                                        orderplan.createTime
                                            ? orderplan.createTime.slice(0, 10)
                                            : '- -'
                                    }}</span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span>关联合同</span>
                                    <span v-if="orderplan.contractNo">
                                        <el-button
                                            type="text"
                                            @click="
                                                onOrderDetail(
                                                    orderplan.contractNo
                                                )
                                            "
                                            style="color: #2370eb"
                                            >{{
                                                orderplan.contractNo
                                            }}</el-button
                                        >
                                    </span>
                                    <span v-else>- -</span>
                                </p>
                                <p>
                                    <span>购买类型</span>
                                    <!-- <span>{{
                                        $tableDataHandle.productCategory(
                                            orderplan.productCategory
                                        )
                                    }}</span> -->
                                    <!-- 11.1 周家文 -->
                                    <span>{{
                                        orderplan.productCategory == 1
                                            ? orderplan.orderType == 1
                                                ? '购买'
                                                : orderplan.orderType == 2
                                                ? '续费'
                                                : '增购'
                                            : orderplan.productCategory == 2
                                            ? orderplan.orderType == 1
                                                ? '购买'
                                                : orderplan.orderType == 2
                                                ? '续费'
                                                : '增购'
                                            : orderplan.productCategory == 12
                                            ? orderplan.orderType == 1
                                                ? '购买'
                                                : '续费'
                                            : orderplan.productCategory == 4
                                            ? orderplan.orderType == 1
                                                ? '正式'
                                                : orderplan.orderType == 2
                                                ? '续费'
                                                : '增购'
                                            : '- -'
                                    }}</span>
                                </p>
                                <p>
                                    <span>版本</span>
                                    <span
                                        class="text"
                                        v-if="orderplan.productCategory == 1"
                                        >{{
                                            orderplan.version == 1
                                                ? '老版'
                                                : orderplan.version == 2
                                                ? '新版'
                                                : '- -'
                                        }}</span
                                    >
                                    <span
                                        class="text"
                                        v-else-if="
                                            orderplan.productCategory == 2
                                        "
                                        >{{
                                            orderplan.version == 1
                                                ? '标准版'
                                                : orderplan.version == 2
                                                ? '尊享版'
                                                : orderplan.version == 3
                                                ? '集团版'
                                                : '- -'
                                        }}</span
                                    >
                                    <span
                                        class="text"
                                        v-else-if="
                                            orderplan.productCategory == 4
                                        "
                                        >{{
                                            orderplan.version == 1
                                                ? 'Hmail旗舰版'
                                                : orderplan.version == 2
                                                ? 'Hmail5G'
                                                : orderplan.version == 3
                                                ? '尊享版'
                                                : '- -'
                                        }}</span
                                    >
                                    <span v-else>- -</span>
                                </p>
                            </div>

                            <div>
                                <p>
                                    <span>用户数</span
                                    ><span>{{
                                        orderplan.orderNumber || 0
                                    }}</span>
                                </p>
                                <p>
                                    <span>年限</span
                                    ><span>{{
                                        Math.round(orderplan.orderTerm) || 0
                                    }}</span>
                                </p>
                                <p>
                                    <span>应收金额</span
                                    ><span>{{
                                        orderplan.receivableAmount
                                    }}</span>
                                </p>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
        </div>
        <div class="opt_history">
            <p class="title">
                <span>
                    <i class="iconfont icon-dingdan3" style="color: #2370eb"></i
                    >操作历史</span
                >
            </p>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="data.dealCustomerHistoryBOList"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
                :expand-row-keys="expands"
                :row-key="getRowKeys"
            >
                <el-table-column width="10"> </el-table-column>
                <el-table-column
                    label="操作日期"
                    width="160"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span type="text">{{
                            scope.row.createTime
                                ? scope.row.createTime.slice(0, 10)
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="操作人员"
                    width="100"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span type="text">{{
                            scope.row.adminName ? scope.row.adminName : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="操作日志" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span type="text">{{
                            scope.row.remark ? scope.row.remark : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
        </div>
    </div>
</template>
<script>
import { closedCusGet, closedCusEdit } from '@/api/closedCus/closedCus';
import { orderGet } from '@/api/order/order.js';
export default {
    data() {
        return {
            data: {},
            tableData: [{}],
            type: 0,
            expands: [],
            getRowKeys(row) {
                return row.id;
            },
            orderplan: {},
            isTable: false,
            isField: false,
            input: '',
            dealNumber: 0,
        };
    },
    mounted() {
        this.type = this.$route.query.type;
        if (this.$route.query.isField == 'true') {
            this.isField = true;
        }
        if (this.$route.query.isField == 'false') {
            this.isField = false;
        }
        this.isTable = true;
        this.getData();
    },
    methods: {
        returnName() {
            var str = '';
            this.data.departmentAdminDOList &&
            this.data.departmentAdminDOList.length > 0
                ? this.data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        returnTrem(row){
            if(this.type == 1){
                if(row.planType == 1 || row.planType == 2){
                    return (row.term / 365).toFixed(2);
                }else{
                    return row.term;
                }
            }else{
                return row.term;
            }
        },
        getData() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            closedCusGet(data).then((res) => {
                console.log(this.data);
                res.data.userNumber = 0;
                res.data.userTerm = 0;
                res.data.minOpenTime = 0;
                res.data.maxExpireTime = 0;
                if (this.type == 1) {
                    if (
                        res.data.orderPlanBOList &&
                        res.data.orderPlanBOList.length > 0
                    ) {
                        res.data.minOpenTime =
                            res.data.orderPlanBOList[0].openTime;

                        res.data.maxExpireTime =
                            res.data.orderPlanBOList[0].expireTime;

                        res.data.orderPlanBOList.forEach((itm) => {
                            if (
                                new Date().getTime() <=
                                    new Date(itm.expireTime).getTime() &&
                                new Date().getTime() >=
                                    new Date(itm.openTime).getTime()
                            ) {
                                res.data.userNumber += itm.number;
                            } else if (
                                new Date().getTime() >
                                new Date(itm.expireTime).getTime()
                            ) {
                                res.data.userNumber += itm.number;
                            }
                            if (
                                itm.planType != 1 &&
                                itm.planType != 2 &&
                                itm.planType != 7
                            ) {
                                res.data.userTerm += itm.term;
                            }
                            if (
                                new Date(res.data.minOpenTime).getTime() >=
                                new Date(itm.openTime).getTime()
                            ) {
                                res.data.minOpenTime = itm.openTime;
                            }
                            if (
                                new Date(res.data.maxExpireTime).getTime() <=
                                new Date(itm.expireTime).getTime()
                            ) {
                                res.data.maxExpireTime = itm.expireTime;
                            }
                        });
                    } else {
                        if (res.data.reportExpireTime && res.data.reportTime) {
                            let date =
                                new Date(res.data.reportExpireTime).getTime() -
                                new Date(res.data.reportTime).getTime();
                            let leftsecond = parseInt(date / 1000);
                            res.data.userTerm = Math.floor(
                                leftsecond / (60 * 60 * 24)
                            );
                        }
                    }
                } else {
                    if (
                        res.data.orderPlanBOList &&
                        res.data.orderPlanBOList.length > 0
                    ) {
                        res.data.minOpenTime =
                            res.data.orderPlanBOList[0].openTime;
                        res.data.maxExpireTime =
                            res.data.orderPlanBOList[0].expireTime;
                        res.data.orderPlanBOList.forEach((itm) => {
                            if (
                                new Date().getTime() <=
                                    new Date(itm.expireTime).getTime() &&
                                new Date().getTime() >=
                                    new Date(itm.openTime).getTime()
                            ) {
                                res.data.userNumber += itm.number;
                            } else if (
                                new Date().getTime() >
                                new Date(itm.expireTime).getTime()
                            ) {
                                res.data.userNumber += itm.number;
                            }

                            res.data.userTerm += itm.term;

                            if (
                                new Date(res.data.minOpenTime).getTime() >=
                                new Date(itm.openTime).getTime()
                            ) {
                                res.data.minOpenTime = itm.openTime;
                            }
                            if (
                                new Date(res.data.maxExpireTime).getTime() <=
                                new Date(itm.expireTime).getTime()
                            ) {
                                res.data.maxExpireTime = itm.expireTime;
                            }
                        });
                    } else {
                        if (res.data.reportExpireTime && res.data.reportTime) {
                            let date =
                                new Date(res.data.reportExpireTime).getTime() -
                                new Date(res.data.reportTime).getTime();
                            let leftsecond = parseInt(date / 1000);
                            res.data.userTerm =
                                Math.floor(leftsecond / (60 * 60 * 24)) / 365;
                        }
                    }
                }

                res.data.recoveryTime = this.$searchTime.getNextDate(
                    res.data.reportExpireTime,
                    -90
                );

                this.data = res.data;
                this.tableData = res.data.orderPlanBOList;
                if(this.type == 1){
                    let number = 0;
                    for(let i=0;i<this.tableData.length;i++){
                        let itm = this.tableData[i];
                        number = number + itm.number;
                        if(itm.planType == 3 || itm.planType == 4 || itm.planType == 5 || itm.planType == 6){
                            break;
                        }
                    }
                    if(number == 0){
                        this.dealNumber = this.data.number;
                    }else{
                        this.dealNumber = number;
                    }
                }else{
                    this.dealNumber =  this.data.number || 0; 
                }
            });
        },
        clickExpandMore(row) {
            let data = {
                param: {
                    id: row.orderId,
                },
            };
            orderGet(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    if (res.data) {
                        this.orderplan = res.data;
                    }
                }
            });
            this.handleExpandChange(row.id);
        },
        handleExpandChange(row, expandedRows) {
            var index = this.expands.indexOf(row);
            if (index != -1) {
                this.expands.splice(index, 1);
            } else {
                this.expands = [];
                this.expands.push(row);
            }
        },
        onOrderDetail(row) {
            this.$router.push({
                path: '/conlist',
            });
            sessionStorage.setItem('contractNo', row);
        },
        getRecoveryTime(dtstr) {
            if (dtstr) {
                var s = dtstr.split('-');
                var yy = parseInt(s[0]);
                var mm = parseInt(s[1]);
                var dd = parseInt(s[2]);
                var dt = new Date(yy, mm, dd);

                var num = dt.getMonth() + parseInt(3);
                if (num / 12 > 1) {
                    yy += Math.floor(num / 12);
                    mm = num % 12;
                } else {
                    mm += parseInt(3);
                }
                return yy + '-' + mm + '-' + dd;
            } else {
                return '- -';
            }
        },
        submitUpdate() {
            let data = {
                param: {
                    id: this.data.id,
                    purpose: this.data.purpose,
                },
            };
            closedCusEdit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData();
                }
            });
        },
        onClose() {
            window.history.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
.details_box {
    width: 100%;
    height: 100%;
    .cust_box {
        width: 100%;

        background: url('../../../../assets/title-bg.png') no-repeat;
        background-size: 100% 100%;
    }
    .title {
        display: flex;

        padding: 17px 15px 13px 17px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        i {
            color: #2370eb;
            margin-right: 12px;
        }
        span {
            display: flex;
            align-items: center;
        }
    }
    .info_box {
        display: flex;
        padding-left: 36px;
        div {
            flex: 1;
            display: flex;
            flex-direction: column;

            p {
                padding-bottom: 16px;
                span:nth-child(1) {
                    font-size: 14px;
                    color: #666;
                    display: inline-block;
                    width: 100px;
                }
                span:nth-child(2) {
                    font-size: 14px;
                    color: #333333;
                    display: inline-block;
                }
            }
        }
    }
    .orderInfo_box {
        display: flex;
        padding-left: 36px;
        div {
            flex: 1;
            p {
                padding-bottom: 16px;
                span:nth-child(1) {
                    font-size: 14px;
                    color: #666;
                    display: inline-block;
                    width: 70px;
                }
                span:nth-child(2) {
                    font-size: 14px;
                    color: #333333;
                }
            }
        }
    }
    .table_box {
        margin-top: 12px;
        background: #fff;
    }
    .opt_history {
        margin-top: 12px;
        background: #fff;
    }
}
.purpose_time {
    position: absolute;
    bottom: -5px;
    left: 10px;
    font-size: 13px;
    color: #999999;
    text-align: right;
    width: 260px;
}
/deep/ .el-textarea__inner {
    resize: none;
}
</style>
